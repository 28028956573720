import React from 'react';
import Alex from '../../Assets/AlexDorval.JPG';

const About = () => {



  return (
    
      <section className='flex min-h-screen flex-wrap items-center'>
        <div className= 'w-full md:w-1/2'>
        <h2 className='my-8 p-2 text-4xl font-bold md:text-5xl lg:text-[7rem]'>
          Alex Dorval
        </h2>
        <p className='mb-8 p-2 text-xl'>
        I am a highly motivated Senior at Montana State University studying Mechanical Engineering Technologies. With extensive experience using Solidworks, Python, React JS and AutoCad to design products and solve problems. I have worked on a multitude of teams demonstrating my ability to devlop products and lead others.
        </p>
        </div>
        <div className='w-full md:w-1/2 lg:p-8'>
            <div className=' flex justify-center'>
              <img src={Alex} alt='Alex-Dorval' className='rounded-3xl'/>

            </div>

        </div>
        
      </section>
    
  )
}

export default About