import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter,RouterProvider} from 'react-router-dom';
import About from './Components/MainPages/About';
import Contact from './Components/MainPages/Contact';
import Skills from './Components/MainPages/Skills';
import Home from './Components/MainPages/Home';
import Project from './Components/MainPages/Project';
import WorkExperience from './Components/MainPages/WorkExperience';

const router= createBrowserRouter([
  {
    path: '/',
    element: <App/>,
    children:[
      {
      path: '/',
      element: <Home/>,
      },
      {
        path: '/about',
        element: <About/>,
      },
      {
        path: '/skills',
        element: <Skills/>,
      },
      {
        path: '/workexperience',
        element: <WorkExperience/>,
      },
      {
        path: '/projects',
        element: <Project/>,
        
      },
      {
        path: '/contact',
        element: <Contact/>,
      },
      
      
    ]
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <App />
    </RouterProvider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
