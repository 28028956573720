import React from 'react'
import MainLogo from '../../Assets/MainLogo.svg'
import SimpleLogo from '../../Assets/SimpleLogo.svg';

const Home = () => {
  return (
    <div className=' flex w-3/4 pt-16  justify-center items-center' id='home'>
      
      <img src={MainLogo} alt='Main Logo' className='mx-auto w-3/4 relative rounded-2xl border-stone-50/30 bg-white/15 pt-3 backdrop-blur-lg'/>
      
      {/* <img src={SimpleLogo} alt='Simple Logo' className='lg:hidden flex rounded-2xl  border-stone-50/30 bg-white/15 py-3 backdrop-blur-lg'/> */}
    </div>
  )
}

export default Home