import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes,FaBars } from 'react-icons/fa';
import SimpleLogo from '../../Assets/SimpleLogo';
import { FaLinkedin } from 'react-icons/fa'

const NavBar = () => {
    const  [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleMobileMenu=()=>{
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

  return (
    <div>
        <nav className='fixed left-0 right-0  top-4 z-50'>
            {/* Desktop Menu */}
            <div className='mx-auto hidden max-w-2xl items-center justify-center rounded-lg border-stone-50/30 bg-white/15 py-3 backdrop-blur-lg lg:flex'>
                <div className=' flex items-center justify-between gap-6 '>
                    <div>
                        <SimpleLogo/>
                    </div>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/alex-dorval/'>
                        <FaLinkedin className=''/>
                    </a>
                    <div>
                        
                            
                                <Link to='/' href='/'>
                                Home
                                </Link>

                            
                            
                        
                    </div>
                    <div>
                            
                                <Link to='/about' href='/about'>
                                    About
                                </Link>
                            
                    </div>
                    <div>
                            
                                <Link to='/skills' href='/skills'>
                                    Skills
                                </Link>
                            
                    </div>
                    <div>
                        
                            <Link to='/workexperience' href='/workexperience'>
                            Work Experience
                            </Link>
                        
                    </div>
                    <div>
                            
                                <Link to='/projects' href='/projects'>
                                    Projects
                                </Link>
                            
                    </div>
                    <div>
                            
                                <Link to='/contact' href='/contact'>
                                    Contact
                                </Link>
                            
                    </div>
                </div>
            </div>
            {/* Mobile Menu */}
            <div className='rounded-lg backdrop-blur-md lg:hidden'>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center justify-between'>
                            <SimpleLogo/>
                            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/alex-dorval/' className='pl-4'>
                                <FaLinkedin className=''/>
                            </a>
                        </div>
                        
                        <div className='flex items-center'>
                            <button className='focus:outline-none' onClick={toggleMobileMenu}>
                                {isMobileMenuOpen ? <FaTimes className='m-2 h-6 w-5'/>:<FaBars className='m-2 h-6 w-5'/>}
                            </button>

                        </div>
                    </div>
                    {isMobileMenuOpen &&(<ul className="ml-4 item-center mt-4 flex flex-col gap-4 backdrop-blur-md">
                    <div>
                        
                            <a className= 'flex' onClick={()=>setIsMobileMenuOpen(false)} href='/'>
                                <Link to='/' href='/'>
                                Home
                                </Link>

                            </a>
                            
                        
                    </div>
                    <div>
                            <a onClick={()=>setIsMobileMenuOpen(false)} href='/about'>
                                <Link to='/about' href='/about'>
                                    About
                                </Link>
                            </a>
                    </div>
                    <div>
                            <a onClick={()=>setIsMobileMenuOpen(false)} href='/skills'>
                                <Link to='/skills' href='/skills'>
                                    Skills
                                </Link>
                            </a>
                    </div>
                    <div>
                        <a onClick={()=>setIsMobileMenuOpen(false)} href='/workexperience'>
                            <Link to='/workexperience' href='/workexperience'>
                            Work Experience
                            </Link>
                        </a>
                    </div>
                    <div>
                            <a onClick={()=>setIsMobileMenuOpen(false)} href='/projects'>
                                <Link to='/projects' href='/projects'>
                                    Projects
                                </Link>
                            </a>
                    </div>
                    <div>
                            <a onClick={()=>setIsMobileMenuOpen(false)} href='/contact'>
                                <Link to='/contact' href='/contact'>
                                    Contact
                                </Link>
                            </a>
                    </div>
                    </ul>)}
            </div>
        </nav>
    </div>
  )
}

export default NavBar

