import React from 'react'
import NavBar from './Components/MainPages/NavBar'
import { Outlet } from 'react-router-dom'
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url,
).toString();

const App = () => {
  return (
    <div className='relative h-full overflow-y-auto antialiased'>
      <div className='fixed inset-0 bg-fixed bg-cover bg-center bg-img'></div>
      <div className='relative z-10 flex flex-col items-center p-4  space-y-8 container mx-auto '>
        <div>
        <NavBar/>
        </div>
        <Outlet/>
        
        
      </div>
      
    </div>
  )
}

export default App

