import { RiReactjsLine } from "react-icons/ri";
import { FaPython } from "react-icons/fa";
import { SiArduino } from "react-icons/si";
import SolidWorks from'../../Assets/swcube.svg';
import Welding from '../../Assets/TigWelding.png';
import LinuxCNC from '../../Assets/linuxcnclogo.png';
import Websocket from '../../Assets/Websocket.png';
import VMA from '../../Assets/VMA.png';
import CurrentGrade from '../../Assets/GradeCals.png';
import ArduinoCar from '../../Assets/310RCar2.png';
import MotorTorque from '../../Assets/MotorTorque.png'
import Vise from '../../Assets/CustomVise.png'
import Capstone from '../../Assets/Capstone.png'
import React from 'react'
export const SKILLS = [
    {
      icon: <RiReactjsLine className="text-4xl text-cyan-400 lg:text-5xl" />,
      name: "React",
      experience: "1 year",
    },
    {
      icon: <img src={SolidWorks} className="text-4xl text-green-600 lg:text-5xl w-10 h-10" />,
      name: "Solidworks",
      experience: "6 years",
    },
    {
      icon: <FaPython className="text-4xl text-blue-500 lg:text-5xl" />,
      name: "Python",
      experience: "2 years",
    },
    {
      icon: <SiArduino className="text-4xl text-cyan-600 lg:text-5xl" />,
      name: "Arduino",
      experience: "1+ year",
    },
    {
      icon: <img src={Welding} className="" style={{height:36, width: 36}} />,
      name: "Welding",
      experience: "7+ years",
    },
    {
      icon: <img src={LinuxCNC} className="text-4xl text-sky-700 lg:text-5xl" style={{height:36, width: 36}}  />,
      name: "Linux CNC",
      experience: "1+ year",
    },
    {
        icon: <img src={Websocket} style={{height:36, width: 36}}/>,
        name: 'Websocket',
        experience: "1+ year",
    }
  ];


  export const ProjectList=[
    {
        id: 1,
        name: "Custom Vise",
        description: "I designed, modeled and then built a custom vise. Using SolidWorks to complete the modeling and generate the drawings. A manual mill and a manual lather to fabricate the vise.",
        image: Vise,
        link: '/projects/customvise',
    },
    {
        id: 2,
        name: 'Senior Capstone',
        description: 'Currently completing a two semester long capstone project for Spectrum Labs at Montana State University. Designing and fabricating a fiber optic positioner for them to use.',
        image: Capstone,
        link: '/projects/customvise',

    },
    {
        id: 3,
        name: 'Arduino Controlled Rover',
        description: 'Myself and the team I was a part of designed, fabricated and coded an arduino controlled rover over the course of a semester. It was able to drive, steer and detect RFID tags remotely.',
        image: ArduinoCar,
        link: '/projects/customvise',
        
    },
    {
        id: 4,
        name: 'Grade Calculator',
        description: 'A MatLab program that uses the grading criteria for the course, the grades you currently achieved to calculate the total grade in the class.',
        image: CurrentGrade,
        link: '/projects/customvise',
        
    },
    {
        id: 5,
        name: 'Voltage Mesh Analysis Program',
        description: 'A Matlab program that is able to run a Voltage Mesh Analysis using nodes based on the criteria chosen by the user.',
        image: VMA,
        link: '/projects/customvise',
        
    },
    {
        id: 6,
        name: 'Torque Graph Calulator For Motors',
        description: 'A basic website designed to calculate and generate the torque graph for a single DC motor.',
        image: MotorTorque,
        link: '/projects/customvise',
        
    },










  ];

  export const Experience=[
    {
      title: 'Undergraduate Researcher',
      company: 'Montana State University',
      duration: 'February 2023 - Present', 
      description: 'Currently working with a 5-axis CNC machine to develop a custom user interface that interacts with a custom backend. Allowing for the user to alter how the machine operates, allowing it to meet specified outcomes.',

    },
    {
      title:'Teachers Assistant',
      company: 'Montana State University',
      duration: 'September 2023 - Present',
      description:' I have/am a teachers assistant for ETME 202 which teaches students how to use excel and microcontrollers. Specifically using a Raspberry Pi Pico with MicroPython',
    },
    {
      title:'Waterfront/Aquatics Director',
      company:'Treasure Valley Scout Reservation',
      duration:'June 2024 - August 2024',
      description:'I was in charge of running a safe and enjoyable waterfront at a nationally accredited summer camp. In order to do this I trained all of the lifeguards under my management. Along with training I was also in charge of all of the equipment, organizing who was teaching what merit badges and teaching scouts merit badges myself.',

    },
    {
      title:'Vocational Technologies Director',
      company: 'Treasure Valley Scout Reservation',
      duration: 'June 2023 - August 2023',
      description: 'I developed and created the Vocational Technologies area at Treasure Valley Scout Reservation and then ran it for the summer. Running the area included teaching the welding, metalwork, automotive maintenance and farm mechanics merit badges. As well as working with the program director and camp director to make sure all requirements were met as well as having all the resources necessary to run an amazing program.',
    },
    {
      title:'Sales Associate',
      company: 'The Home Depot',
      duration: 'October 2022 - April 2023',
      description: 'I worked in the Hardware Department of the Bozeman Home Depot. Working to make sure all the aisles were organized and stocked. I also helped customers find what they needed and guided them to the product/products.',
    },
    {
      title: 'Welding Instructor',
      company:'Treasure Valley Scout Reservation',
      duration:'June 2022 - August 2022',
      description: 'I taught campers how to weld using the SMAW(Stick) and GMAW(Mig) processes over the course of a week. Making sure all the materials that were needed were there and that the environment was safe and enjoyable.',
    },
    {
      title: 'Welder',
      company: 'Fabco Manufacturing Inc.',
      duration: 'September 2020 - February 2021',
      description:'I was a production GTAW(Tig) welder. I worked on a multitude of products ranging from 1/8" Aluminum to 26 gauge steal.',
    }


  ];