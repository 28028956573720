import React from 'react'
import { ProjectList } from './constants'

const Project = () => {
  return (
    <section className=' pt-20' id='projects'>
      <h2 className='mb-8 text-center text-3xl lg:text-4xl'>
      Projects
      </h2>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
        {ProjectList.map((project) => (
            <div key={project.id} className="group relative overflow-hidden  items-center justify-center rounded-3xl">
              <h3 className='m-2 text-xl text-center'>{project.name}</h3>
              <img src={project.image} alt={project.name} className='h-full w-full object-cover transition-transform rounded-3xl duration-500 group-hover:scale-110'/>
              <div className='absolute pt-16 inset-0 overscroll-auto overflow-auto flex flex-col items-center justify-center text-black opacity-0 backdrop-blur-xl transition-opacity duration-500 group-hover:opacity-100'>
                  
                  <p className=' mb-12 p-4'> {project.description}</p>
                  
              </div>
            </div>
        ))}
      </div>
    </section>
  )
}

export default Project